import React, { useState, useEffect, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer } from 'recharts';

const HomeOwnershipCalculator = () => {
  const [inputs, setInputs] = useState({
    totalValue: 360877,
    cashdownPercentage: 5,
    ownershipPercentage: 100,
    mortgageRate: 5,
  });
  const [totalCost, setTotalCost] = useState(0);
  const [cashdownAmount, setCashdownAmount] = useState(0);
  const [yearlyData, setYearlyData] = useState([]);
  const [currentYear, setCurrentYear] = useState(10);
  const [monthlyRent, setMonthlyRent] = useState(1400);
  const [showAllYears, setShowAllYears] = useState(false);
  const [rentalValue, setRentalValue] = useState(0);

  const QUEBEC_TAX_RATE = 0.14975;

  useEffect(() => {
    const newTotalCost = calculateTotalCost();
    setTotalCost(newTotalCost);
    const newCashdownAmount = (newTotalCost * inputs.cashdownPercentage * inputs.ownershipPercentage) / 10000;
    setCashdownAmount(newCashdownAmount);
    calculateCosts(newTotalCost, newCashdownAmount);
  }, [inputs]);

  const calculateTotalCost = () => {
    const taxes = inputs.totalValue * QUEBEC_TAX_RATE;
    const taxeDeBienvenue = calculateTaxeDeBienvenue(inputs.totalValue);
    return inputs.totalValue + taxes + taxeDeBienvenue;
  };

  const calculateTaxeDeBienvenue = (value) => {
    let tax = 0;
    if (value <= 58900) {
      tax = value * 0.005;
    } else if (value <= 294600) {
      tax = 58900 * 0.005 + (value - 58900) * 0.01;
    } else {
      tax = 58900 * 0.005 + (294600 - 58900) * 0.01 + (value - 294600) * 0.015;
    }
    return tax;
  };

  const calculateCMHCInsurance = (value, downPayment) => {
    const downPaymentPercentage = (downPayment / value) * 100;
    let premium;
    if (downPaymentPercentage >= 20) {
      premium = 0;
    } else if (downPaymentPercentage >= 15) {
      premium = 0.028;
    } else if (downPaymentPercentage >= 10) {
      premium = 0.031;
    } else if (downPaymentPercentage >= 5) {
      premium = 0.04;
    } else {
      premium = 0; // Not allowed less than 5%
    }
    return value * premium;
  };

  const handleInputChange = (name, value) => {
    setInputs(prev => ({ ...prev, [name]: Number(value) }));
  };

  const calculateCosts = (newTotalCost, newCashdownAmount) => {
    const ownershipFactor = inputs.ownershipPercentage / 100;
    const adjustedTotalCost = newTotalCost * ownershipFactor;
    const adjustedCashdownAmount = newCashdownAmount;
    
    const cmhcInsurance = calculateCMHCInsurance(adjustedTotalCost, adjustedCashdownAmount);
    const initialLoanAmount = adjustedTotalCost - adjustedCashdownAmount + cmhcInsurance;
    const monthlyRate = inputs.mortgageRate / 100 / 12;
    const numberOfPayments = 25 * 12;

    const monthlyMortgage = (initialLoanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    const yearlyDataCalculated = [];
    let currentHouseValue = inputs.totalValue * ownershipFactor;
    let remainingMortgage = initialLoanAmount;

    for (let year = 1; year <= 25; year++) {
      const annualInflation = 0.03;

      const interestPayment = remainingMortgage * monthlyRate;
      const principalPayment = monthlyMortgage - interestPayment;

      const monthlyTaxes = (currentHouseValue * 0.015) / 12;
      const monthlyInsurance = (currentHouseValue * 0.005) / 12;
      const monthlyMaintenance = (currentHouseValue * 0.01) / 12;
      
      const monthlyHouseValueIncrease = (currentHouseValue * annualInflation) / 12;
      
      const monthlyData = {
        year: year,
        mortgage: principalPayment,
        interest: interestPayment,
        taxes: monthlyTaxes,
        insurance: monthlyInsurance,
        maintenance: monthlyMaintenance,
        houseValueIncrease: monthlyHouseValueIncrease,
        currentHouseValue: currentHouseValue,
        remainingMortgage: remainingMortgage,
        savings: principalPayment + monthlyHouseValueIncrease,
        costs: interestPayment + monthlyTaxes + monthlyInsurance + monthlyMaintenance,
        total: principalPayment + interestPayment + monthlyTaxes + monthlyInsurance + monthlyMaintenance,
      };

      yearlyDataCalculated.push(monthlyData);

      currentHouseValue += currentHouseValue * annualInflation;
      remainingMortgage -= principalPayment * 12;
    }

    setYearlyData(yearlyDataCalculated);
  };

  const adjustedData = useMemo(() => {
    return yearlyData.map(item => {
      const convertedAmount = Math.min(rentalValue, item.costs);
      const newSavings = item.savings + convertedAmount;
      const newCosts = Math.max(0, item.costs - rentalValue);
      const additionalSavings = Math.max(0, rentalValue - item.costs);
      return {
        ...item,
        savings: newSavings + additionalSavings,
        costs: newCosts,
        total: newSavings + newCosts + additionalSavings
      };
    });
  }, [yearlyData, rentalValue]);

  const displayData = showAllYears ? adjustedData : adjustedData.slice(0, 10);

  const maxTotal = useMemo(() => {
    return Math.max(...displayData.map(item => item.total));
  }, [displayData]);

  const CombinedPaymentBox = ({ data }) => {
    const totalMonthlyPayment = data.total;
  
    const costs = [
      { label: 'Interest', value: data.interest, color: '#FFA500' },
      { label: 'Taxes', value: data.taxes, color: '#FFFF00' },
      { label: 'Insurance', value: data.insurance, color: '#FFC0CB' },
      { label: 'Maintenance', value: data.maintenance, color: '#87CEFA' },
      { label: 'Mortgage', value: data.mortgage, color: '#4CAF50' },
    ];
  
    const TOTAL_HEIGHT = 400;
  
    return (
      <div style={{ marginBottom: '30px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', padding: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', borderRadius: '8px', overflow: 'hidden', height: `${TOTAL_HEIGHT}px` }}>
          {costs.map((cost, index) => {
            const heightPercentage = (cost.value / totalMonthlyPayment) * 100;
            const pixelHeight = Math.max((heightPercentage / 100) * TOTAL_HEIGHT, 20);
            return (
              <div key={index} style={{
                height: `${pixelHeight}px`,
                backgroundColor: cost.color,
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
                color: '#333',
                fontSize: '14px',
                fontWeight: 'bold',
                position: 'relative',
                overflow: 'hidden'
              }}>
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  width: '100%', 
                  zIndex: 1,
                  textShadow: '1px 1px 2px rgba(255,255,255,0.7), -1px -1px 2px rgba(255,255,255,0.7), 1px -1px 2px rgba(255,255,255,0.7), -1px 1px 2px rgba(255,255,255,0.7)'
                }}>
                  <span>{cost.label}</span>
                  <span>${cost.value.toFixed(2)}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const NewChart = () => {
    return (
      <div className="font-sans text-gray-900 p-2">
        <h2 className="text-xl font-semibold mb-2">Monthly Housing Payments Over Time</h2>
        <div className="mb-4">
          <label htmlFor="rentalValue" className="block text-sm font-medium text-gray-700">
            Rental Value: ${rentalValue}
          </label>
          <input
            type="range"
            id="rentalValue"
            name="rentalValue"
            min="0"
            max="3000"
            step="10"
            value={rentalValue}
            onChange={(e) => setRentalValue(Number(e.target.value))}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          />
        </div>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            layout="vertical"
            data={displayData}
            margin={{
              top: 5,
              right: 30,
              left: 30,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <XAxis type="number" domain={[0, maxTotal]} hide />
            <YAxis dataKey="year" type="category" width={30} axisLine={false} tickLine={false} fontSize={10} />
            <Tooltip 
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0].payload;
                  return (
                    <div className="bg-white p-2 rounded shadow text-sm">
                      <p className="font-bold">Year {data.year}</p>
                      <p>Savings: ${Math.round(data.savings)}</p>
                      <p>Costs: ${Math.round(data.costs)}</p>
                      <p className="font-bold">Total: ${Math.round(data.total)}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Bar dataKey="savings" stackId="a" fill="#34D399" radius={[4, 0, 0, 4]}>
              <LabelList dataKey="savings" position="inside" fill="#fff" fontSize={9} />
            </Bar>
            <Bar dataKey="costs" stackId="a" fill="#F97316" radius={[0, 4, 4, 0]}>
              <LabelList dataKey="costs" position="inside" fill="#fff" fontSize={9} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <button 
          onClick={() => setShowAllYears(!showAllYears)} 
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          {showAllYears ? "Show Less" : "Show More"}
        </button>
      </div>
    );
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '600px', margin: '0 auto', padding: '15px' }}>
      <h1 style={{ fontSize: '24px', marginBottom: '15px' }}>New Home Ownership Cost Calculator (Quebec)</h1>
      
      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>Project's total value before tax:</label>
        <input
          type="number"
          value={inputs.totalValue}
          onChange={(e) => handleInputChange('totalValue', e.target.value)}
          style={{ width: '100%', padding: '5px' }}
        />
      </div>
  
      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>Personal Ownership: {inputs.ownershipPercentage}%</label>
        <input
          type="range"
          value={inputs.ownershipPercentage}
          onChange={(e) => handleInputChange('ownershipPercentage', e.target.value)}
          min={1}
          max={100}
          step={1}
          style={{ width: '100%' }}
        />
      </div>
  
      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>
          Personal cashdown: {inputs.cashdownPercentage}% (${cashdownAmount.toFixed(2)})
        </label>
        <input
          type="range"
          value={inputs.cashdownPercentage}
          onChange={(e) => handleInputChange('cashdownPercentage', e.target.value)}
          min={5}
          max={100}
          step={1}
          style={{ width: '100%' }}
        />
      </div>
  
      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>Mortgage interest rate: {inputs.mortgageRate}%</label>
        <input
          type="range"
          value={inputs.mortgageRate}
          onChange={(e) => handleInputChange('mortgageRate', e.target.value)}
          min={1}
          max={10}
          step={0.1}
          style={{ width: '100%' }}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>Current Monthly Rent: ${monthlyRent}</label>
          <input
            type="range"
            value={monthlyRent}
            onChange={(e) => setMonthlyRent(Number(e.target.value))}
            min={500}
            max={5000}
            step={50}
            style={{ width: '100%' }}
          />
        </div>
        
        {yearlyData[currentYear - 1] && (
          <div>
            <NewChart />
            
            <div style={{ marginBottom: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', padding: '20px' }}>
              <h2 style={{ fontSize: '24px', marginBottom: '15px', textAlign: 'center' }}>Monthly Cost Breakdown at Year {currentYear}</h2>
              <input
                type="range"
                value={currentYear}
                onChange={(e) => setCurrentYear(Number(e.target.value))}
                min={1}
                max={25}
                step={1}
                style={{ width: '100%' }}
              />
            </div>
            
            <CombinedPaymentBox data={yearlyData[currentYear - 1]} />
            
            <div style={{ marginBottom: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', padding: '15px' }}>
              <h3 style={{ marginBottom: '10px', fontSize: '18px', textAlign: 'center' }}>Monthly Summary for Year {currentYear}</h3>
              <p>Total Monthly Payment: ${yearlyData[currentYear - 1].total.toFixed(2)}</p>
              <p>Monthly Savings: ${yearlyData[currentYear - 1].savings.toFixed(2)}</p>
              <p>Monthly Costs: ${yearlyData[currentYear - 1].costs.toFixed(2)}</p>
              <p>Current House Value: ${Math.round(yearlyData[currentYear - 1].currentHouseValue).toLocaleString()}</p>
              <p>Remaining Mortgage: ${Math.round(yearlyData[currentYear - 1].remainingMortgage).toLocaleString()}</p>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default HomeOwnershipCalculator;